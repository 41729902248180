import React, { useEffect, useRef, useState } from "react";
import { StateWithArrayStructure } from "../../@types";
import CustomTextArea from "../CustomTextArea";
import { Button } from "../Styles";
import FormCards from "./InputCard";
import tw from "tailwind-styled-components";

interface Props<K extends keyof StateWithArrayStructure> {
  name: K;
  onChange: (key: K, values: string[]) => void;
}

/**
 * StringArrayForm Component
 * This component provides a form to manage an array of strings.
 * It allows adding, editing, and deleting values in the array.
 *
 * @param onChange - Function to be called when the values array changes.
 * @param name - Name or key corresponding to the component's state.
 */
function StringArrayForm<K extends keyof StateWithArrayStructure>({
  onChange,
  name,
}: Props<K>) {
  const [values, setValues] = useState<string[]>([]);
  const [error, setError] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const addValue = () => {
    setError(!Boolean(inputRef.current?.value.trim()));
    if (inputRef.current && inputRef.current?.value.trim()) {
      const newValues = [...values, inputRef.current?.value];
      setValues(newValues);
      inputRef.current.value = "";
    }
  };
  const deleteValues = (e: string) => {
    setValues(values.filter((i) => e !== i));
  };
  const editValue = (e: string) => {
    deleteValues(e);
    if (inputRef.current) {
      inputRef.current.value = e;
    }
  };

  useEffect(() => {
    onChange(name, values);
  }, [name, onChange, values]);
  return (
    <FlexItem className="gap-5">
      <FlexItem className="gap-6">
        {values.map((i) => (
          <FormCards
            handleEdit={() => editValue(i)}
            handledDelete={() => deleteValues(i)}
            key={i}
          >
            <p className="break-words w-full"> {i}</p>
          </FormCards>
        ))}
      </FlexItem>
      <CustomTextArea
        ref={inputRef}
        className="min-h-[100px]"
        isError={error}
        onChange={() => {}}
      />
      <div className="flex justify-end">
        <Button onClick={addValue}>Add</Button>
      </div>
    </FlexItem>
  );
}

const FlexItem = tw.div`flex flex-col`;

export default StringArrayForm;
