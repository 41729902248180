import Container from "components/Container";
import { EyeIcon } from "components/Icons";
import Typography from "components/Typography";
import React from "react";

const preferansesText = [
  "As fellow sojourners in this segment, we have a deep understanding of the needs and pain points.",
  "We’re building a suite of tools and services to make it easier to do business. From the inception of a new opportunity to the response to partnerships, our vision is to provide software tools that will save time and simplify workflows.",
  "We fund this with our consulting work which is typically sourced from projects at the intersection of construction, technology, and sustainability.",
];

const text = [
  "Federal, state, and municipal governments purchase numerous goods and services, and doing business with them can be a lifeline for small and large businesses alike.",
  "Success in this segment is dependent on expertise, networks, rules, hard work, and, yes, luck. Getting any business started and sustainable is a difficult endeavor, and most will fail in the first six years. Like all entrepreneurs, owners of small (disadvantaged) businesses face unique challenges in launching and growing their business, and these challenges are often exacerbated by underlying social and economic constraints.",
  "Recognizing these realities, certification programs (in government contracting) make it possible to participate in set-aside contracting. In addition, various resources are available to disadvantaged owners including access to special financing terms, training, technical skill development, mentorship, peer-to-peer networking opportunities, etc.",
  "Despite these and other well-intentioned efforts, a disproportionately large number of these firms struggle with finding and maintaining a steady stream of work and prospering in their fields of expertise.",
];

export default function AboutPage() {
  return (
    <main className="bg-[#F9F9FA] ">
      <div
        style={{
          backgroundImage: "url(7531de39b34cb7190445a41d4c0ca7b4.jpg)",
        }}
        className={"bg-cover bg-center"}
      >
        <div
          style={{
            background: "linear-gradient(268.03deg, rgba(40, 42, 41, 0) -3.57%, rgba(40, 42, 41, 0.72) 98.33%)",
          }}
          className="pt-[160px] pb-10"
        >
          <Container className="text-white">
            <Typography component="h1" variant="h2">
              ABOUT US
            </Typography>
            <Typography component="h2" variant="h2-light">
              Seeing the unseen
            </Typography>
          </Container>
        </div>
      </div>

      <Container className="pt-20">
        <div className="flex flex-col gap-2">
          {text.map((value, index) => (
            <Typography key={index} component="p">
              {value}
            </Typography>
          ))}
        </div>

        <article>
          <div className="text-center my-14">
            <Typography component="h2" variant="h2-light">
              Helping others:
              <br />
              the secret to a more meaningful life
            </Typography>
          </div>

          <Typography component="p">
            While the marketplace offers many useful tools for small businesses, cost, learning curves and investment
            required for customization are significant barriers even before adding the overall resource constraints that
            these businesses operate under.
          </Typography>
        </article>

        <div className="pt-[90px] pb-20">
          <Typography component="h3" variant="h2">
            Preferences
          </Typography>

          <div className="mt-12 grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 gap-4">
            {preferansesText.map((value, index) => (
              <div key={index} className="flex flex-row p-4 bg-[#F3F3F1]">
                <EyeIcon clasName="flex-shrink-0" />
                <Typography component="p">{value}</Typography>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </main>
  );
}
