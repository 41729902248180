import clsx from "clsx";
import React, { memo } from "react";

export interface ButtonProps {
  onClick?: () => void;
  children?: React.ReactNode;
  className?: string;
  variant?: Variant;
  elemtStart?: React.ReactNode;
}

type Variant = undefined | "text" | "contained" | "outlined";

const buttonStyle: { [key: string]: string } = {
  contained: "bg-greenLight-400 hover:bg-greenLight-500/[0.9]",
  outlined: "border-2 border-greenLight-400 text-greenLight-400 hover:bg-greenLight-500/[0.1]",
  text: "text-greenLight-400",
};

function Button({ onClick, children, className, variant = "text", elemtStart }: ButtonProps) {
  return (
    <button
      className={clsx(
        "font-medium rounded text-base text-center overflow-hidden transition-all",
        className,
        buttonStyle[variant]
      )}
      onClick={onClick}
    >
      <div className={clsx("py-2 px-6", "flex items-center gap-3 justify-center", "hovers:bg-gray-50")}>
        {elemtStart}
        {children}
      </div>
    </button>
  );
}

export default memo(Button);
