import clsx from "clsx";
import { memo, useCallback, useState } from "react";
import { HiArrowRight } from "react-icons/hi";
import Button from "./Button";
import Container from "./Container";
import RecentProjectPopUp from "./RecentProjectPopUp";
import Typography from "./Typography";

function RecentProject() {
  const [show, setShow] = useState<boolean>(false);

  const handleToggleShow = useCallback(() => {
    setShow((state) => !state);
  }, []);

  function handleHide() {
    setShow(false);
  }

  return (
    <div
      style={{
        backgroundImage: 'url("56e69b29caa603ec0b6609ba49e65e59.png")',
      }}
      className="bg-cover bg-center relative overflow-hidden"
    >
      <div
        style={{
          background: "linear-gradient(296.78deg, rgba(27, 27, 27, 0) -35.7%, #2A2829 113.56%)",
        }}
      >
        <Container className="h-[800px]">
          <div className="my-auto h-full max-w-[428px]">
            <div className="h-full flex flex-col items-center justify-center gap-6">
              <Typography component="h2" variant="h2" className="text-white uppercase">
                Recent projects
              </Typography>
              <Button variant="contained" onClick={handleToggleShow}>
                Project specs <HiArrowRight />
              </Button>
            </div>
          </div>

          <div className="overflow-hidden">
            <RecentProjectPopUp close={handleHide} className={clsx(show ? "" : "left-full", "transition-all right-0")} />
          </div>
        </Container>
      </div>
    </div>
  );
}

export default memo(RecentProject);
