import React, { memo } from "react";
import Container from "./Container";
import Typography from "./Typography";
import FeedbackCard, { FeedbackCardProps } from "./FeedbackCard";

const feedBackList: FeedbackCardProps[] = [
  {
    image: "images/person/person1.jpg",
    text: "We teamed with the 3RDI team on the Hart Island Land Capacity Study Project, and it has been as good of an experience as we’ve had working as a sub in our 20+ years in business. Alyssa Loorya, President, and Lead Investigator of Chrysalis, only hopes that we can continue to work with Roy and the 3RDI team on future projects.",
    personInfo: "Christopher Ricciardi, Chrysalis Archaeological Consultants",
  },
  {
    image: "images/person/person2.png",
    text: "Accuscan provided subsurface investigations and surveys for the Hart Island Capacity Study. Working with 3RDI was a great experience. They were extremely knowledgeable and organized when it came to this project and did well to keep both the client and other team members on the same page. We were paid promptly and received editorial guidance from 3RDI on our final deliverable. We look forward to our next project with 3RDI.",
    personInfo: "Brian Thomsen, Accu Scan GPR Corp.",
  },
  {
    image: "images/person/person3.png",
    text: "Reform Architecture subcontracted under 3RDI in a planning study recently, and we look forward to doing more projects with them again soon! Roy Sirengo is a pleasure to work with; he is transparent and has excellent communication skills and respects the means to accomplish project goals.",
    personInfo: "Karim Ahmed, Reform Architecture",
  },
];

function Testimonials() {
  return (
    <div className="bg-[#FAFAFB] py-[96px]">
      <Container>
        <Typography component="h3" variant="h2">
          Testimonials
        </Typography>
        <div className="mt-[72px] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
          {feedBackList.map((value, index) => (
            <FeedbackCard key={index} {...value} />
          ))}
        </div>
      </Container>
    </div>
  );
}

export default memo(Testimonials);
