import React, { memo } from "react";
import Container from "./Container";
import Typography from "./Typography";
import ListItem from "./ListItem";

const companyLogos = [
  "company-logos/0a665f89370291ed2770bcdf31321fc4.png",
  "company-logos/c894462c8479c1ddd2a241bd90ba7a38.png",
  "company-logos/13c0d492b7479a9852567296c2754901.png",
];

const certifications = [
  "MBE, NYCSBS (expires 10/31/24)",
  "MBE, PANYNJ (expires 09/1/2025)",
  "SBE, PANYNJ (expires 09/26/2026)",
  "MBE, NYSDMWBD (expires 10/21/2026)",
  "MBE, NJAC (expires 11/22/2024)",
  "SB, PA (expires 06/07/2025)",
  "SDB, PA (expires 06/07/2025)",
  "MBE, NJAC (expires 11/22/2024)",
];

function ProudMember() {
  return (
    <div className="py-24 bg-[#ECECE9]">
      <Container>
        <div className="flex flex-col md:flex-row gap-14">
          <div className="max-w-[512px] flex flex-col gap-4">
            <Typography component="h3" variant="h2" className="max-w-[394px] ">
              PROUD MEMBER OF THE FOLLOWING
            </Typography>
            <Typography component="h4" variant="h2-light">
              ASSOCIATIONS
            </Typography>
            <div className="py-[42px] flex flex-row flex-wrap gap-6">
              {companyLogos.map((value, index) => (
                <img key={index} className="h-14" src={value} alt="company logo" />
              ))}
            </div>
          </div>

          <div>
            <ul className="flex flex-col gap-4">
              {certifications.map((value, index) => (
                <ListItem key={index}>
                  <Typography component="span" variant="body2" className="text-black">
                    {value}
                  </Typography>
                </ListItem>
              ))}
            </ul>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default memo(ProudMember);
