import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Routes } from "route";

const navRoutes: { title: string; link: Routes | string }[] = [
  { title: "Home", link: Routes.Home },
  { title: "Capability Statement", link: Routes.СapabilityStatement },
  { title: "Templates", link: Routes.Template },
  { title: "About", link: Routes.About },
];
function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <>
      <div className=" shadow-md bg-white z-50 fixed w-full top-0 flex justify-center">
        <header className="flex justify-between items-center p-5 py-2 w-full max-w-[1500px]">
          <Link to="/">
            <figure className="w-16">
              <img src="/images/icons/3rdi_logo.png" width={336} height={215} alt="3rdi_logo" className="w-full" />
            </figure>
          </Link>
          <div>
            <button onClick={toggleMenu} className="p-1 rounded-md border-2 sm:hidden flex flex-col items-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                {isMenuOpen ? (
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
                  />
                )}
              </svg>
            </button>
            <nav
              className={`absolute ${
                isMenuOpen ? "right-0" : "-right-full"
              } h-screen sm:h-auto w-screen sm:w-auto bg-white sm:right-0  sm:relative`}
            >
              <ul className="flex flex-col sm:flex-row items-center overflow-hidden h-auto">
                {navRoutes.map((navItem) => (
                  <Link
                    key={navItem.title}
                    to={navItem.link}
                    onClick={() => {
                      isMenuOpen && toggleMenu();
                    }}
                  >
                    <p className="mx-5 lg:mx-5 cursor-pointer p-3 hover:font-bold text-lg text-green-700">
                      {navItem.title}
                    </p>
                  </Link>
                ))}
              </ul>
            </nav>
          </div>
        </header>
      </div>
    </>
  );
}

export default Header;
