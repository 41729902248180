import { memo, useState } from "react";
import Accordion from "./Accordion";
import Typography from "./Typography";
import { clsx } from "clsx";

const recentProject: { name: string; client: string; location: string; offer: string; description: string }[] = [
  {
    name: "Hart Island Capacity Study",
    client: "NYCDSS",
    location: "Hart Island, Bronx, NY",
    offer: "Project management",
    description:
      "3RDI assembled and led a multi-disciplinary team that assessed current and future capacity based on current and future operational needs, climate change, soil erosion, soil conditions, industry, trends and other factors. The team identified simple operational improvements that if implemented would increase capacity by 64%.",
  },
  {
    name: "Delta Air Lines, Terminal 4 Addition, JFK Airport",
    client: "Delta Airlines | Turner Construction",
    location: "Jamaica, Queens, NY",
    offer: "Document Control",
    description:
      "3RDI provided document control and project submittals (e-Builder® via Procore®) for the 374,500 sq. ft., four-story, nine-gate addition to the existing International Air Terminal 4 at JFK International Airport.",
  },
  {
    name: "Hart Island Burial Operations",
    client: "JPL | NYCDSS",
    location: "Hart Island, Bronx, NY",
    offer: "Procore® Administrator",
    description:
      "3RDI assisted the client in understanding the technical needs of the RFP and then evaluated and tested several off-the-market products before selecting Procore® as the CIMS (Cemetery Information Management System) best suited to the projectas required by the client.  3RDI then mapped and configured Procore® to provide the required operational and functional needs of the client, imported field data, and trained JPL staff to use Procore® in data collection from the field.",
  },
  {
    name: "Capability Statement Application",
    client: "Any small business owner",
    location: "Virtual",
    offer: "Application development",
    description:
      "3RDI built this application to help small business owners create a Capability Statement for free. We will continue adding more features and maintaining this application.",
  },
];

export interface RecentProjectPopUpProps {
  close: () => void;
  className?: string;
}

function RecentProjectPopUp({ close, className }: RecentProjectPopUpProps) {
  const [active, setActive] = useState<number>(-1);
  return (
    <div
      className={clsx(
        "absolute top-0 right-0",
        className,
        "flex flex-col",
        "max-w-full sm:max-w-[604px]",
        "w-full h-full max-h-full bg-greenDark-500 pb-7"
      )}
    >
      <div className="pl-8 border-greenLight-500 border-b flex justify-between items-center">
        <Typography variant="h2" className="uppercase text-white">
          Recent projects
        </Typography>
        <button className="p-[34px] border-greenLight-500 border-l" onClick={close}>
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1 29L29 1M29 29L1 1"
              stroke="#FAFAFB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
      {recentProject.map((value, index) => (
        <div
          className="h-full transition-all"
          key={index}
          onClick={() => {
            setActive(index !== active ? index : -1);
          }}
        >
          <Accordion labal={value.name} id={index} activeId={active}>
            <Typography className="font-semibold mb-[10px]">{value.name}</Typography>
            <Typography variant="body3-light">
              Client: {" "}
              <Typography component="span" variant="body3">
                {value.client}
              </Typography>
            </Typography>
            <Typography variant="body3-light" className="my-1">
              Location: {" "}
              <Typography component="span" variant="body3">
                {value.location}
              </Typography>
            </Typography>
            <Typography variant="body3-light">
              Service offered: {" "}
              <Typography component="span" variant="body3">
                {value.offer}
              </Typography>
            </Typography>

            <Typography variant="body3" className="mt-8">
              {value.description}
            </Typography>
          </Accordion>
        </div>
      ))}
    </div>
  );
}

export default memo(RecentProjectPopUp);
