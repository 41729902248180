import clsx from "clsx";
import Container from "components/Container";
import Typography from "components/Typography";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Routes } from "route";

export interface FooterProps {
  renderJoin?: boolean;
}

const contacts = [
  {
    city: "New York",
    address: "418 Northern Blvd, Ste 304",
    area: "Long Island City,",
    zip: "NY - 11101",
    phone: "(908) 291-8256",
    business: "<business> | at | 3rdi dot tech",
  },
  {
    city: "Florida",
    address: "7901 4th St. N,  Ste 300",
    area: "St. Petersburg,",
    zip: "FL 33702",
    phone: "(908) 291-8256",
    business: "<business> | at | 3rdi dot tech",
  },
];

export default function Footer() {
  const loc = useLocation();
  const [renderJoin, setRenderJoin] = useState<boolean>(false);

  useEffect(() => {
    const render = loc.pathname === Routes.СapabilityStatement;
    setRenderJoin(render);
  }, [loc]);

  return (
    <footer className="bg-[#F9F9FA] w-full">
      <Container className="pt-[90px]">
        <div
          className={clsx(
            "flex flex-col sm:flex-row flex-wrap gap-8 sm:gap-2 sm:gap-y-8",
            "items-center sm:items-start",
            renderJoin ? "justify-around lg:justify-between" : "justify-between"
          )}
        >
          <article className="order-1">
            <Typography component="h5" variant="h2" className="mb-[10px]">
              CONTACT
            </Typography>
            <div className=" flex flex-row gap-8">
              {contacts.map((contact) => (
                <div key={contact.zip} className="flex flex-col gap-5">
                  <div>
                    <Typography component="p" variant="body1-bold">{contact.city}</Typography>
                    <Typography component="p">{contact.address}</Typography>
                    <Typography component="p">{contact.area}</Typography>
                    <Typography component="p">{contact.zip}</Typography>
                  </div>

                  <div>
                    <Typography component="p" className="font-medium">
                      {contact.phone}
                    </Typography>
                    <Typography component="p">{contact.business}</Typography>
                  </div>
                </div>
              ))}
            </div>
          </article>
          {renderJoin ? (
            <div id="mc_embed_signup" className="lg:order-2 order-4">
              <form
                action="https://tech.us14.list-manage.com/subscribe/post?u=804b3c4181d7e9d410cc5bc69&amp;id=5282baacd4&amp;f_id=0034e4e0f0"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_self"
              >
                <div id="mc_embed_signup_scroll" className="grid gap-3">
                  <div className="indicates-required">
                    <Typography component="p" variant="body1-bold">
                      Join our news letter
                    </Typography>
                  </div>
                  <div>
                    <Typography component="p" className="mb-2">
                      Stay up to date about
                    </Typography>
                    <div className="mc-field-group flex bg-white rounded-full overflow-hidden">
                      <input
                        type="email"
                        name="EMAIL"
                        placeholder="Email Address"
                        className="required email rounded-full  p-3 pr-10 flex-1 focus:!border-none"
                        id="mce-EMAIL"
                        required
                      />
                      <input
                        type="submit"
                        value="Subscribe"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        className="button bg-greenLight-400 py-3 font-medium text-white px-12 -ml-10 rounded-full"
                      />
                    </div>
                  </div>
                  <div hidden>
                    <input type="hidden" name="tags" value="7164015" />
                  </div>
                  <div id="mce-responses" className="clear">
                    <div className="response hidden" id="mce-error-response"></div>
                    <div className="response hidden" id="mce-success-response"></div>
                  </div>
                  {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
                  <div aria-hidden="true" className="absolute left-[-5000px]">
                    <input type="text" name="b_804b3c4181d7e9d410cc5bc69_5282baacd4" tabIndex={-1} value="" />
                  </div>
                </div>
              </form>
            </div>
          ) : null}

          <img src="/images/icons/3rdi_logo.png" width={170} height={106} alt="3rdi_logo" className="order-3" />
        </div>

        <div className="pt-14 pb-8">
          <Typography component="p" className="text-center">
            © Copyright 2024. All Rights Reserved by 3RDI Technologies
          </Typography>
        </div>
      </Container>
    </footer>
  );
}
