import React, { useRef, useState } from "react";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import ReactDom from "react-dom";
import { getCroppedImg } from "../Utils";
import Tooltip from "rc-tooltip";

interface Props {
  onChange: (e: string | undefined) => void;
}

function UploadLogoButton({ onChange }: Props) {
  const [selectedImage, setSelectedImage] = useState<string | null>("");
  const [croppedImage, setCroppedImage] = useState<string>();
  const imageRef = useRef<HTMLImageElement>();
  const tooltipRef = useRef(null);
  const [crop, setcrop] = useState<Partial<Crop>>({
    aspect: 1 / 1,
    unit: "%",
    width: 50,
  });

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        //@ts-ignore
        setSelectedImage(reader.result);
        console.log(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleLogoUpload = async (action: "upload" | "reset") => {
    switch (action) {
      case "upload":
        {
          //@ts-ignore
          const blob = await getCroppedImg(imageRef.current, crop, "dddd");
          setCroppedImage(blob);
          onChange(blob);
          setSelectedImage(null);
        }
        break;

      case "reset":
        setCroppedImage("");
        onChange(undefined);
        break;

      default:
        break;
    }
  };

  // If you setState the crop in here you should return false.
  const onImageLoaded = (image: HTMLImageElement) => {
    imageRef.current = image;
  };

  const onCropChange = (crop: Crop, percentageCrop: Crop) => {
    setcrop(crop);
  };

  return (
    <div className="flex flex-col">
      {croppedImage ? (
        <div className="mb-3 relative self-start mt-3">
          <figure className="w-28 h-28 overflow-hidden">
            <img
              width={200}
              height={200}
              src={croppedImage}
              alt=""
              className="w-full"
            />
          </figure>
          <button
            onClick={() => handleLogoUpload("reset")}
            className="absolute -top-3  -right-3 text-white bg-red-600 w-10 h-10 rounded-full"
          >
            X
          </button>
        </div>
      ) : (
        <Tooltip
          placement="left"
          overlay={"Upload an image of aspect ration 1/1"}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
          // visible={Boolean(toolTipMessage && isFocused)}
          ref={tooltipRef}
        >
          <div className="border-2 relative border-green-600 rounded-full p-3 text-center">
            <p>Upload business logo</p>
            <input
              type="file"
              accept="image/*"
              className="absolute top-0 left-0 h-full w-full opacity-0"
              onChange={onSelectFile}
            />
          </div>
        </Tooltip>
      )}
      {selectedImage &&
        ReactDom.createPortal(
          <div className="fixed w-full h-screen z-50 left-0 top-0 flex justify-center items-center bg-black bg-opacity-95">
            <div className="h-[80vh] lg:w-1/2 w-[80%] flex items-center gap-10">
              <ReactCrop
                className=" overflow-scroll"
                src={selectedImage}
                crop={crop}
                onImageLoaded={onImageLoaded}
                onComplete={onCropChange}
                onChange={onCropChange}
              />
              <button
                className="p-3 bg-green-600 text-white rounded-md px-6"
                onClick={() => handleLogoUpload("upload")}
              >
                Done
              </button>
            </div>
          </div>,
          document.getElementsByTagName("html")[0]
        )}
    </div>
  );
}

export default UploadLogoButton;
