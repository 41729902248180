import React from "react";
import Typography from "./Typography";
import Container from "./Container";
import { EyeIcon } from "./Icons";

export default function ProductAndService() {
  const text = [
    "e-PMIS systems (Procore®, e Builder®, Raken®)",
    "Training and skill building in job order contracting, IdiQ, and numerous construction software programs such as e-Builder®, Procore®, e-Gordian®, PlanGrid®, PlanSwift®, Raken®, etc.",
    "Software development",
    "Data visualization, KPI development, storytelling",
    "Misc. professional services",
  ];

  return (
    <div className="bg-greenDark-500 py-[80px] w-full">
      <Container>
        <div className="max-w-[725px]">
          <Typography component="h2" variant="h2" className="text-white mb-10">
            PRODUCT & SERVICES
          </Typography>
          <div className="flex flex-col  gap-4">
            {text.map((value, index) => (
              <div key={index} className="flex flex-row items-center gap-6">
                <EyeIcon color="#fff" />
                <Typography component="p" className="text-white font-bold">
                  {value}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
}
