import { useAppDispatch, useAppSelector } from ".";
import { uiStateType, updateFormUi } from "../redux/features/FormUi";

/**
 * This
 */
function useUpdateFormUIstate() {
  const uiState = useAppSelector((state) => state.formUI.value);
  const dispatch = useAppDispatch();
  const setState = (e: uiStateType) => dispatch(updateFormUi(e));

  return [uiState, setState] as const;
}

export default useUpdateFormUIstate;
