import clsx from "clsx";

export interface EyeIconProps {
  width?: number;
  height?: number;
  clasName?: string;
  color?: string;
}

export default function EyeIcon({ height, width, clasName, color }: EyeIconProps) {
  return (
    <div className={clsx(clasName, "p-2")}>
      <svg
        width={width ? width : 24}
        height={height ? height : 16}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 16"
        fill={color || "#222021"}
      >
        <path
          d="M23.1985 8.45656C23.1639 8.5325 22.3426 10.3559 20.5285 12.17C18.1014 14.5934 15.0423 15.875 11.6673 15.875C8.2923 15.875 5.23324 14.5934 2.80886 12.17C0.994799 10.3559 0.173549 8.5325 0.136049 8.45656C0.0725365 8.31257 0.0397339 8.15691 0.0397339 7.99953C0.0397339 7.84215 0.0725365 7.6865 0.136049 7.5425C0.170737 7.46562 0.991987 5.64312 2.80699 3.82906C5.23324 1.40562 8.2923 0.125 11.6673 0.125C15.0423 0.125 18.1014 1.40562 20.5248 3.82906C22.3398 5.64312 23.161 7.46562 23.1957 7.5425C23.2597 7.6863 23.293 7.84185 23.2935 7.99923C23.2939 8.15662 23.2616 8.31237 23.1985 8.45656ZM18.8804 5.36281C16.8676 3.38094 14.4414 2.375 11.6673 2.375C8.89324 2.375 6.46699 3.38094 4.45699 5.36375C3.66606 6.14635 2.98571 7.03329 2.4348 8C2.98587 8.96632 3.66621 9.85293 4.45699 10.6353C6.46792 12.6191 8.89324 13.625 11.6673 13.625C14.4414 13.625 16.8667 12.6191 18.8776 10.6353C19.6685 9.853 20.3488 8.96638 20.8998 8C20.3488 7.03335 19.6685 6.14642 18.8776 5.36375L18.8804 5.36281ZM11.6673 12.125C10.8515 12.125 10.0539 11.8831 9.37557 11.4298C8.69722 10.9766 8.16851 10.3323 7.8563 9.57857C7.54409 8.82482 7.4624 7.99542 7.62156 7.19525C7.78072 6.39508 8.17359 5.66008 8.75048 5.08318C9.32738 4.50629 10.0624 4.11342 10.8626 3.95426C11.6627 3.7951 12.4921 3.87679 13.2459 4.189C13.9996 4.50121 14.6438 5.02992 15.0971 5.70827C15.5504 6.38663 15.7923 7.18415 15.7923 8C15.7911 9.09364 15.3561 10.1421 14.5827 10.9154C13.8094 11.6888 12.7609 12.1238 11.6673 12.125ZM11.6673 6.125C11.2965 6.125 10.9339 6.23497 10.6256 6.44099C10.3173 6.64702 10.0769 6.93986 9.93503 7.28247C9.79311 7.62508 9.75598 8.00208 9.82833 8.36579C9.90067 8.72951 10.0793 9.0636 10.3415 9.32583C10.6037 9.58805 10.9378 9.76663 11.3015 9.83897C11.6652 9.91132 12.0422 9.87419 12.3848 9.73227C12.7274 9.59036 13.0203 9.35004 13.2263 9.04169C13.4323 8.73335 13.5423 8.37084 13.5423 8C13.5423 7.50272 13.3448 7.02581 12.9931 6.67418C12.6415 6.32254 12.1646 6.125 11.6673 6.125Z"
          fill={color || "#222021"}
        />
      </svg>
    </div>
  );
}
