import React, { ReactNode, memo } from "react";

export interface ListItemProps {
  children: ReactNode;
}

function ListItem({ children }: ListItemProps) {
  return (
    <li className="flex items-center">
      <div className="w-2 h-2 mr-2 rounded-full bg-greenDark-500"></div>
      {children}
    </li>
  );
}

export default memo(ListItem);
