import Introduction from "components/Introduction";
import ProductAndService from "components/ProductAndService";
import ProudMember from "components/ProudMember";
import ProudMemberSite from "components/ProudMemberSite";
import RecentClients from "components/RecentClients";
import RecentPartners from "components/RecentPartners";
import RecentProject from "components/RecentProject";
import Testimonials from "components/Testimonials";
import { memo } from "react";

function Landing() {
  return (
    <main>
      {/* <PrintButton /> */}
      <Introduction />
      <ProductAndService />
      <Testimonials />
      <ProudMember />
      <RecentProject />
      <ProudMemberSite />
      <RecentClients />
      <RecentPartners />
      <div className="bg-customGreen border-customGreen text-customGreen"></div>
    </main>
  );
}

export default memo(Landing);
