import { memo } from "react";
import Container from "./Container";
import Typography from "./Typography";

const companyLogosLink: { link: string; alt: string }[] = [
  { link: "company-logos/nycdss.png", alt: "Logo NYC Departamnt of Social Services" },
  { link: "company-logos/nycdhs.png", alt: "Logo NYC Departamnt of Homeles Services" },
  { link: "company-logos/panalrs.png", alt: "Logo Port Authority Nynj Air Land Rail Sea" },
  { link: "company-logos/snyc.png", alt: "Logo State of New York Comptroller" },
  { link: "company-logos/delta.png", alt: "Logo Delta" },
  { link: "company-logos/nycca.png", alt: "Logo NYC Cultural Affairs" },
  { link: "company-logos/vlp.png", alt: "Logo Volunteer Lawyers Project of CNY, INC" },
];

function RecentClients() {
  return (
    <div className="bg-[#222021] pt-20 pb-[72px]">
      <Container>
        <Typography component="h2" variant="h2" className="text-white">
          RECENT CLIENTS
        </Typography>
        <div className="mt-[74px] flex flex-row flex-wrap justify-around gap-y-3">
          {companyLogosLink.map((value, index) => (
            <img key={index} src={value.link} alt={value.alt} />
          ))}
        </div>
      </Container>
    </div>
  );
}

export default memo(RecentClients);
