import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import { Routes as Path } from "./route";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Editor from "./Pages/Editor";
import Templates from "./components/Templates";
import { Header } from "./components";
import Landing from "./Pages/Landing";
import { Footer } from "layout";
import AboutPage from "Pages/AboutPage";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className="flex flex-col items-center w-full relative">
          <Header />
          {/* max-w-[1500px] */}
          <div className=" w-full min-h-screen  mt-[68px]">
            <Routes>
              <Route path={Path.Home} element={<Landing />} />
              <Route path={Path.СapabilityStatement} element={<Home />} />
              <Route path={Path.Template} element={<Templates />} />
              <Route path={Path.About} element={<AboutPage />} />
              <Route path={Path.Template + "/:id"} element={<Editor />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
