import React from "react";
import Typography from "./Typography";

export interface FeedbackCardProps {
  image: string;
  text: string;
  personInfo: string;
}

export default function FeedbackCard({ image, personInfo, text }: FeedbackCardProps) {
  return (
    <div className="flex flex-col gap-4 p-3">
      <img
        src={image}
        className="w-20 h-20 object-cover object-center rounded-full mx-auto"
        width={80}
        height={80}
        alt={"person photo " + personInfo}
      />
      <Typography component="p" variant="body2">“{text}”</Typography>
      <Typography variant="body2-semibold">{personInfo}</Typography>
    </div>
  );
}
