import clsx from "clsx";
import React, { memo } from "react";

export interface ContainerProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

function Container({ children, className, style }: ContainerProps) {
  return (
    <div style={style} className={clsx("container mx-auto px-4 sm:px-4 xl:px-6", className)}>
      {children}
    </div>
  );
}

export default memo(Container);
