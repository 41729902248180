import React, { memo } from "react";
import Container from "./Container";
import Typography from "./Typography";
import SiteCard, { SiteCardProps } from "./SiteCard";

const sites: SiteCardProps[] = [
  {
    link: "https://usblackchambers.org",
    src: "images/site/2f6891e31d53aa5691bce7124ae7f8fa.png",
    title: "usblackchambers.org",
  },
  {
    link: "https://www.queenschamber.org",
    src: "images/site/53cbf689fb19bf215763b927688ffe31.png",
    title: "queenschamber.org",
    imgStyle: "object-fill",
  },
  {
    link: "https://www.namctristate.org",
    src: "images/site/fa0461d4a66ab11ab4eb55aa8524a745.png",
    title: "namctristate.org",
    imgStyle: "object-fill",
  },
];

function ProudMemberSite() {
  return (
    <div className="pt-24 pb-[106px] bg-[#D4D4CD]">
      <Container>
        <Typography variant="h2" component="h2" className="mb-2">
          PROUD MEMBER OF THE
        </Typography>
        <Typography variant="h2-light" component="h3">
          FOLLOWING ASSOCIATIONS:
        </Typography>
        <div className="mt-14 flex flex-col sm:flex-row flex-wrap items-center justify-evenly xl:justify-between gap-y-8">
          {sites.map((value, index) => (
            <SiteCard key={index} {...value} />
          ))}
        </div>
      </Container>
    </div>
  );
}

export default memo(ProudMemberSite);
