import React, { memo, useCallback, useState } from "react";
import Typography from "./Typography";
import Container from "./Container";
import ListItem from "./ListItem";
import Button from "./Button";
import clsx from "clsx";

const recentPartners: { text: string; link: string }[] = [
  { link: "https://www.1empiregroup.com", text: "1 Empire Group" },
  { link: "https://www.applesandorangespr.com", text: "Apples and Oranges Public Relations" },
  { link: "https://www.chrysalisarchaeology.com", text: "Chrysalis Archaeology" },
  { link: "https://eastonarch.com", text: "Easton Architects" },
  { link: "http://www.artconic.com", text: "ArtCONIC" },
  { link: "https://www.turnerconstruction.com", text: "Turner " },
  { link: "https://embracechange.nyc", text: "Embrace Change" },
  { link: "http://www.veolianorthamerica.com", text: "Veolia " },
  { link: "https://obengengineering.com/", text: "Obeng Engineering Group" },
  { link: "melanie@mcunninghamlaw.com", text: "Melanie Cunningham Law Office, PC" },
  { link: "http://www.vbalaw.com", text: "Vaughn J. Buffalo | Corporate Attorney" },
  { link: "http://www.accuscangpr.nyc/", text: "AccuScan" },
  { link: "http://www.numerexonline.com/", text: "Numerex Services" },
  { link: "https://sykimls.com/", text: "SY Kim" },
  { link: "https://www.doppelmayr.com/en/", text: "Doppelmayr" },
  { link: "https://hodgestrategies.com/", text: "SBI Strategies Inc." },
  { link: "http://www.jackgoodnoe.com/", text: "Jack Goodnoe" },
  { link: "http://www.otecyber.com/", text: "Eric Perry" },
  { link: "http://www.levelengineering.com/", text: "Level Engineering and Inspection" },
  { link: "https://rootedconsulting.org/", text: "Rooted Consulting" },
  { link: "https://www.navvis.com/", text: "Navvis" },
  { link: "https://bimstream.com/", text: "BIMStream " },
  { link: "https://www.levelengineering.com/", text: "Level Engineering" },
  // { link: "", text: "" },
];

function RecentPartners() {
  const [showAll, setShowAll] = useState<boolean>(false);

  const handleToggleShow = useCallback(() => {
    setShowAll((state) => !state);
  }, []);

  return (
    <div className="py-[72px] bg-[#FAFAFB]">
      <Container>
        <Typography component="h2" variant="h2" className="uppercase mb-[74px]">
          RECENT Partners
        </Typography>
        <div
          className={clsx(
            showAll ? "max-h-full  before:hidden" : "max-h-[160px] before:block",
            "mb-10 relative overflow-hidden  before:absolute before:h-32 before:w-full before:bottom-0  before:pointer-events-none before:from-[#FAFAFB] before:bg-gradient-to-t"
          )}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-[20px]">
            {recentPartners.map((value, index) => (
              <ListItem key={index}>
                <a href={value.link} className="underline">
                  <Typography className="font-medium">{value.text}</Typography>
                </a>
              </ListItem>
            ))}
          </div>
        </div>
        <div className="flex justify-center">
          <Button variant="outlined" className="mx-auto" onClick={handleToggleShow}>
            {showAll ? "Hide" : "Show all"}
          </Button>
        </div>
      </Container>
    </div>
  );
}

export default memo(RecentPartners);
