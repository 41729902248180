import { memo } from "react";
import clsx from "clsx";

export interface SiteCardProps {
  src: string;
  link: string;
  title: string;
  imgStyle?:string;
}

function SiteCard({ link, src, title, imgStyle: srcStyle }: SiteCardProps) {
  return (
    <div className="rounded-lg bg-[#F9F9FA] max-w-[406px] overflow-hidden">
      <img src={src} className={clsx("h-[180px] w-full max-w-[406px] object-cover", srcStyle)} alt={"Image site " + title} />
      <a href={link} target="_blank">
        <p className="py-2 text-2xl font-light text-center hover:underline">{title}</p>
      </a>
    </div>
  );
}

export default memo(SiteCard);
