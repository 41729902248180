import React, { FC, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  children: JSX.Element | JSX.Element[];
}

const Modal: FC<ModalProps> = ({ open, children, onClose }) => {
  const ref = useRef<HTMLBodyElement | null>(null);
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    ref.current = document.getElementsByTagName("body")[0];
    setMounted(true);
  }, []);
  return mounted && open
    ? createPortal(
        <div
          onClick={() => {
            onClose();
          }}
          className="fixed flex items-center justify-center right-5 top-0 left-0 w-screen h-screen z-40"
        >
          <div className="w-full top-0 h-full absolute bg-black opacity-50 z-[100]"></div>
          <div className="z-[200]">{children}</div>
        </div>,
        // @ts-ignore
        ref.current
      )
    : null;
};

export default Modal;
