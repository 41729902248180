import { useEffect, useState } from "react";
import Modal from "./Modal";

/**
 * Displays an alert if app is opened in a mobile device
 */
function WindowsSizeAlert() {
  const [isOpen, setIsOpen] = useState(true);
  const handleClose = () => setIsOpen(false);
  useEffect(() => {
    const windowsSize = window.innerWidth;
    console.log(windowsSize);
    setIsOpen(windowsSize < 800);
  }, []);

  return (
    <Modal onClose={handleClose} open={isOpen}>
      <div className="bg-white rounded-md p-10 flex flex-col md:flex-row justify-center items-center m-10">
        <img
          src={"/images/icons/document.gif"}
          width={100}
          height={100}
          alt=""
        />
        <div>
          <div>We recommend that you use this tool on a desktop/laptop</div>
          <button className="p-3 px-10 bg-green-400 rounded-sm my-2 font-bold">
            OK
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default WindowsSizeAlert;
