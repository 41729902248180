import { memo } from "react";
import { HiArrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import { Routes } from "route";
import Button from "./Button";
import Container from "./Container";
import Typography from "./Typography";

function Introduction() {
  return (
    <div
      style={{
        backgroundImage: 'url("/21944ea6e745d3ab471c8c50823151b4.png")',
      }}
      className="bg-cover object-center"
    >
      <div
        style={{
          background: "linear-gradient(266.73deg, rgba(40, 42, 41, 0) -15.53%, rgba(30, 30, 30, 0.83) 90.36%)",
        }}
      >
        <Container>
          <div className="max-w-[791px] py-[150px] ">
            <Typography component="h2" variant="h2" className="text-white mb-[40px]">
              3RDI Technologies provides consulting services at the intersection of construction, technology, and
              sustainability.
            </Typography>
            <Typography component="h3" variant="h3" className="text-white">
              We also build tools and services that help small (disadvantaged) businesses succeed in government
              contracting.
            </Typography>
            <div className="flex flex-col sm:flex-row items-center  gap-6 mt-[51px]">
              <Link to={Routes.СapabilityStatement}>
                <Button variant="outlined">PDF of our Capability Statement</Button>
              </Link>
              <Link to={Routes.Template}>
                <Button variant="contained">
                  Create your Capability Statement here
                  <HiArrowRight />
                </Button>
              </Link>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default memo(Introduction);
