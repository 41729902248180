import clsx from "clsx";
import { ReactNode, memo } from "react";

export interface TextProps {
  variant?: TextVariant;
  component?: AllowedTags;
  className?: string;
  children: ReactNode;
}

export type TextVariant =
  | undefined
  | "h1"
  | "h2"
  | "h2-light"
  | "h3"
  | "body1"
  | "body1-bold"
  | "body2"
  | "body2-semibold"
  | "body3"
  | "body3-light";
export type AllowedTags = "p" | "h1" | "h2" | "h3" | "h4" | "h5" | "span" | "div";

const style: { [key: string]: string } = {
  h1: "font-bold text-6xl",
  h2: "font-bold text-5xl",
  "h2-light": "font-light text-5xl",
  h3: "font-light text-4xl",
  body1: "text-2xl font-normal",
  "body1-bold":'text-2xl font-bold',
  body2: "text-xl font-normal",
  "body2-semibold": "text-xl font-semibold",
  body3: "text-sm font-normal",
  "body3-light": "text-sm font-light",
  none: "text-base",
};

function Typography({ className, variant, children, component: Element = "p" }: TextProps) {
  return <Element className={clsx("tracking-normal", className, style[variant || "none"])}>{children}</Element>;
}

export default memo(Typography);
