import clsx from "clsx";
import React, { memo, useEffect, useState } from "react";
import Typography from "./Typography";

interface AccordionProps {
  labal?: string;
  children?: React.ReactNode;
  id: number;
  activeId: number;
}

function Accordion({ children, labal, activeId, id }: AccordionProps) {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    setIsVisible(activeId === id);
  }, [activeId]);

  return (
    <div className={clsx("bg-greenDark-500 border-greenLight-500 border-b h-full transition-all",)}>
      <div
        className={clsx(
          !isVisible ? "visible" : "hidden",
          "h-full flex items-center justify-center cursor-pointer text-center text-white transition-all duration-300 ease-in"
        )}
      >
        <Typography variant="body2-semibold" className="max-w-[264px]">
          {labal}
        </Typography>
      </div>

      <div
        className={clsx(
          isVisible ? "visible" : "hidden",
          "bg-[#F9F9FA] py-10 px-8 transition-all duration-300 ease-in collapse-content"
        )}
      >
        {children}
      </div>
    </div>
  );
}

export default memo(Accordion);
