import { useEffect, useState } from "react";
import detectBrowser from "../Utils/detectbrowerser";
import Modal from "./Modal";

/**
 * Displays an alert if app is not opened in a chrome browser
 */
function BrowserAlert() {
  const [isOpen, setIsOpen] = useState(true);
  const handleClose = () => setIsOpen(false);
  useEffect(() => {
    const browser = detectBrowser();
    const isSupportedBrowser =
      browser === "Chrome" ||
      browser === "Edge ( chromium based)" ||
      browser === "Mozilla Firefox";
    setIsOpen(!isSupportedBrowser);
  }, []);

  return (
    <Modal onClose={handleClose} open={isOpen}>
      <div className="bg-white rounded-md p-10 flex flex-col md:flex-row justify-center items-center m-10">
        <img
          src={"/images/icons/document.gif"}
          width={100}
          height={100}
          alt=""
        />
        <div>
          <div>
            There might be some issues exporting your document on this browser
          </div>
          <div>
            We recommend trying <b>Chrome</b> or <b>Edge (chromium based)</b> on
            a desktop
          </div>
          <button className="p-3 px-10 bg-green-400 rounded-sm my-2 font-bold">
            OK
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default BrowserAlert;
